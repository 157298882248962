import React, { useState } from "react";
import "../Css/login.css";
import img_logo from "../Images/bigcash-logo.png";
import footer_logo from "../Images/logomain.png";
import Select from "react-select";
// import { toast, ToastContainer } from "react-toastify";
import { changeCurrency, loginUser } from "../Data/data";
import Post from "../Api/Post";
import { useNavigate } from "react-router-dom";
import "sweetalert2/dist/sweetalert2.min.css";
// import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css';
import Swal from "sweetalert2";
import classes from "../Css/LoginPage.module.css";
import { MdPhoneAndroid } from "react-icons/md";
import Header from "../Components/Header";
import Modal from "../Components/Modal";
import Loading from "../Components/Loading";
import toast, { Toaster } from "react-hot-toast";
import Loader from "../Components/Loader";

const LoginPage = () => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  // useEffect(()=>{
  //     callonBackend();
  // })

  const options = [
    { value: "USD", label: "United States Dollar (USD)" },
    { value: "ZWG", label: "Zimbabwe Gold (ZWG)" },
  ];

  const handleInputChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleMobileChange = (event) => {
    // Update mobileNumber state when the input value changes
    setMobileNumber(event.target.value);
  };

  const handleLogin = async (e) => {
    if (
      !mobileNumber ||
      mobileNumber.trim() === "" ||
      mobileNumber.trim() === undefined ||
      !selectedOption ||
      selectedOption?.value === "" ||
      selectedOption?.value === undefined
    ) {
      //!selectedOption || selectedOption.value === '' || selectedOption.value ===undefined   ||
      // Show toast notification for incomplete input
      toast.error("Please fill all fields");
      return;
    }

    // Log the selected pack
    // console.log('Selected Pack:', selectedOption.value);

    // Log the mobile number
    console.log("Mobile Number:", mobileNumber);
    localStorage.setItem("ani", mobileNumber);
    localStorage.setItem("currency", selectedOption?.value);

    hitOnBackendForCheckUser(mobileNumber, selectedOption?.value);

    // Clear the states after handling the login action
    // setMobileNumber("");
    setSelectedOption(null);
  };

  // const callonBackend=()=>
  // {
  //   let promise = Get(price);

  //   promise.then((e)=>{
  //       e.forEach((element,index) => {
  //           // Your logic for each element goes here
  //           let dynamicValue = element.amount;

  //           // Update the label property for the corresponding option
  //           options[index].label += dynamicValue;
  //         });
  //   })
  // }

  const hitOnBackendForCheckUser = async (mobileNumber, pack) => {
    //console.log("MobNo:", mobileNumber);

    let request = { ani: mobileNumber, currency: pack };
    //console.log("link", loginUser);
    setLoading(true);
    let promise = await Post(loginUser, request);
    handlingResponse(promise); // Handle the response
    // promise.then((e) => {
    //   //console.log("e ", e);
    //   handlingResponse(e);
    // });
  };

  // const handlingResponse = (e) => {

  //   console.log("response..",e.status)
  //   if (e === 0) {
  //     setLoading(false);

  //     setOpenModal(true);
  //   } else if (e === 2) {
  //     //billing pending
  //     setLoading(false);
  //     toast.error("Billing Pending");
  //     return;
  //   } else if (e === 1) {
  //     //give access to portal
  //     localStorage.setItem("ani", mobileNumber);
  //     localStorage.setItem("pack", null);
  //     setLoading(false);

  //     navigate("/otp");
  //     // navigate("/home");
  //   } else if (e === "Network Error") {
  //     setLoading(false);

  //     //Backend Not Working - so sending to error page
  //     navigate("/error");
  //   } else {
  //     setLoading(false);

  //     toast.error("Please subscribe first");
  //     return;
  //   }
  // };
  const handlingResponse = (response) => {
    console.log("response: ", response);
    const { status, data } = response; // Destructure response status and data

    console.log("response---", status);
    console.log("data----", data);

    console.log("status", status);

    switch (status) {
      case 200: // OK
        // OTP has been sent, navigate to the OTP page
        localStorage.setItem("ani", mobileNumber); // Assuming mobileNumber is available
        localStorage.setItem("currency", selectedOption?.value); // You may need to update this as per your logic
        toast.success("Login successful. OTP sent.");
        setLoading(false);
        console.log("200---", status);
        navigate("/otp");
        break;
      case 500: // Internal Server Error (or other server errors)
      case "Network Error": // Network Error case
        setLoading(false);
        console.log("500---", status);
        toast.error("Network Error. Please try again later.");
        navigate("/error");
        break;
      case 409: // Already Subscribed With Differenct Currency so show message to him currecny chage
        setLoading(false);
        console.log("409---", status);
        let optionUserIsSubscribed = options.filter(
          (data) => data?.value != selectedOption?.value
        );
        setMessage({
          state: true,
          switchTo: selectedOption?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: mobileNumber,
        });
        // toast.error(data);
        // setOpenModal(true);
        break;

      case 404: // Not Found
        console.log("404----", status);
        setLoading(false);
        setOpenModal(true);
        // toast.error(data);
        break;

      case 402: // Charging Failed
        console.log("402----", status);
        setLoading(false);
        toast.error(data);
        break;

      default:
        setLoading(false);
        toast.error("Network Error. Please try again later.");
        navigate("/error");
        break;
    }
  };

  const [message, setMessage] = useState({
    state: false,
    switchTo: "",
    stayWith: "",
    msisdn: "",
  });

  const handelwithsame = async () => {
    const ani = localStorage.getItem("ani");
    const currency = message.stayWith;
    hitOnBackendForCheckUser(ani, currency);
  };
  const handlingResponseforChange = (response) => {
    console.log("response: ", response);
    const { status, data } = response; // Destructure response status and data

    console.log("response..", status);
    console.log("data..", data);

    switch (status) {
      case 200:
      case 202: // OK
        localStorage.removeItem("ani");
        localStorage.removeItem("currency");
        toast.success("Currency Changed successfully");
        setMessage({
          state: false,
        });

        hitOnBackendForCheckUser(message.msisdn, message.switchTo);

        // navigate("/login");
        break;
      case 500: // Internal Server Error (or other server errors)
      case "Network Error": // Network Error case
        setLoading(false);
        toast.error("Network Error. Please try again later.");
        navigate("/error");
        break;
      case 409: // Network Error case
        setLoading(false);
        let optionUserIsSubscribed = options.filter(
          (data) => data?.value != selectedOption?.value
        );
        setMessage({
          state: true,
          switchTo: selectedOption?.value,
          stayWith:
            optionUserIsSubscribed.length > 0 &&
            optionUserIsSubscribed[0]?.value,
          msisdn: mobileNumber,
        });
        // toast.error(data);
        // setOpenModal(true);
        break;

      case 404: // Not Found
        setLoading(false);
        setOpenModal(true);
        break;

      case 402: // Charging Failed
        console.log("402----", status);
        setLoading(false);
        toast.error(data);
        // Redirect to login after 2 seconds
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        break;

      default:
        setLoading(false);

        break;
    }
  };

  const handelChangeCurrency = async () => {
    console.log(message.switchTo);
    let request = { ani: mobileNumber, currency: message.switchTo };
    const response = await Post(changeCurrency, request);
    console.log("response: ", response);
    handlingResponseforChange(response);
  };

  const handleReset = () => {
    setMessage({
      state: false,
      switchTo: "",
      stayWith: "",
      msisdn: "",
    });
  };

  return (
    <>
      <Header tab="tab1">
        <div className={classes.align_center}>
          <div className={classes.form_container}>
            {message?.state == true ? (
              <>
                <div className={classes.back_btn} onClick={handleReset}>
                  &#8617;
                </div>
                <div className={classes.wrapper}>
                  <p className={classes.message_primary}>
                    YOU ARE ALREADY SUBSCRIBED WITH {message.stayWith}
                    <p className={classes.message_secondary}>DO YOU WISH TO:</p>
                  </p>
                  <button
                    type="button"
                    className={classes.switch_btn}
                    disabled={loading}
                    onClick={() => handelChangeCurrency()}
                  >
                    {loading ? <Loading /> : `SWITCH TO ${message.switchTo}`}
                  </button>
                  <button
                    type="button"
                    className={classes.stay_with_btn}
                    disabled={loading}
                    onClick={() => handelwithsame(message.stayWith)}
                  >
                    {loading ? <Loading /> : `STAY WITH ${message.stayWith}`}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className={classes.input_container}>
                  <p>+263</p>
                  <input
                    className={classes.input}
                    type="number"
                    name="number"
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    placeholder="Enter your mobile number"
                  />
                </div>

                <div className={classes.dropdown_container}>
                  <Select
                    className={classes.selector}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: "#00063f",
                        color: "white",
                      }),
                      singleValue: (baseStyles) => ({
                        ...baseStyles,
                        color: "white", // Change the color of the text inside the box
                      }),
                      placeholder: (baseStyles) => ({
                        ...baseStyles,
                        color: "white", // Change the color of the placeholder text
                      }),
                    }}
                    value={selectedOption}
                    onChange={handleInputChange}
                    options={options}
                    placeholder="Select Currency"
                    required
                    defaultMenuIsOpen={true}
                    menuIsOpen={true}
                  />
                </div>

                <button
                  type="submit"
                  onClick={handleLogin}
                  className={classes.btn}
                  disabled={loading}
                >
                  {loading ? <Loading /> : "Login"}
                </button>
              </>
            )}
          </div>

          {/* <div>{loading && <Loading />}</div> */}

          <div className={classes.footer_container}>
            <img
              src={footer_logo}
              alt="econet"
              className={classes.footer_logo}
            />
            <p>
              By clicking <b>login</b>, you have read, understood and agreed to
              be bound by the YoGamezPro
              <b> Terms & Conditions</b> for the service.
            </p>
          </div>

          {/* <ToastContainer /> */}
          <Toaster
            position="top-center"
            style={{
              marginTop: "5em", // Adjust the margin to center the notification vertically
            }}
          />
          {/* <div className={classes.footer_container}>
          <img src={footer_logo} alt="econet" className={classes.footer_logo} />
          <p>
            By clicking <b>login</b>, you have read, understood 
            and agree to be bound by the YoGames360 service’s
            <b>& Conditions and FAQ’s</b></p>
        </div> */}
        </div>
      </Header>
      <Modal open={openModal} closeHandler={closeModal} />
    </>
  );
};

export default LoginPage;
